<template>
  <div v-if="clientSiteData && $can('read', 'clients')">
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Clients
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/reports/type">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  to="/apps/clients/list"
                >
                  Clients
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  :to="'/apps/clients/view/'+clientSiteData.clientname"
                >
                  {{ clientSiteData.clientname }}
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  View Site: {{ clientSiteData.sitename }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientSiteData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching client sites data
      </h4>
      <div class="alert-body">
        No client sites found with this client site name.
      </div>
    </b-alert>

    <template v-if="clientSiteData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="24"
          xl="12"
          lg="12"
          md="12"
        >
          <client-sites-view-client-sites-info-card :client-site-data="clientSiteData" />
        </b-col>
        <b-col
          cols="24"
          xl="12"
          lg="12"
          md="12"
        >
          <client-sites-view-client-sites-info-notes :client-site-data="clientSiteData" />
        </b-col>
        <b-col
          v-if="typeof clientSiteData.geofence !== 'undefined' &&
            typeof clientSiteData.geofence.lat !== 'undefined' &&
            typeof clientSiteData.geofence.lon !== 'undefined' &&
            typeof clientSiteData.geofence.distance !== 'undefined' &&
            clientSiteData.geofence.lat !== '' &&
            clientSiteData.geofence.lon !== '' &&
            clientSiteData.geofence.distance !== ''
          "
          cols="24"
          xl="12"
          lg="12"
          md="12"
        >
          <client-sites-view-client-sites-info-Map :client-site-data="clientSiteData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'

import clientStoreModule from '../clientStoreModule'
import ClientSitesViewClientSitesInfoCard from './ClientSitesViewClientSitesInfoCard.vue'
import ClientSitesViewClientSitesInfoNotes from './ClientSitesViewClientSitesInfoNotes.vue'
import ClientSitesViewClientSitesInfoMap from './ClientSitesViewClientSitesInfoMap.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BBreadcrumb,
    BBreadcrumbItem,

    // Local Components
    ClientSitesViewClientSitesInfoCard,
    ClientSitesViewClientSitesInfoNotes,
    ClientSitesViewClientSitesInfoMap,
  },
  setup() {
    const clientSiteData = ref(null)

    const STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })
    if (ability.can('read', 'clients')) {
      const qvars = {}
      qvars.clientname = router.currentRoute.params.clientname
      qvars.sitename = router.currentRoute.params.sitename

      store.dispatch('app-client/fetchClient', qvars.clientname)
        .then(response1 => {
          const clientData = response1.data
          if (typeof clientData.services === 'undefined') {
            clientData.services = {
              guard: false,
              eyetrax: false,
              guardtour: false,
              tenant: false,
              privateinvestigation: false,
            }
          }
          store.dispatch('app-client/fetchClientSite', qvars)
            .then(response => {
              clientSiteData.value = response.data
              console.log('clientSiteData.value')
              console.log(clientSiteData.value)
              if (typeof clientSiteData.value.client_type === 'undefined') {
                clientSiteData.value.client_type = ''
              }
              if (typeof clientSiteData.value.bill_rate === 'undefined') {
                clientSiteData.value.bill_rate = ''
              }
              if (typeof clientSiteData.value.region_loc === 'undefined') {
                clientSiteData.value.region_loc = ''
              }
              if (typeof clientSiteData.value.notes_admin === 'undefined') {
                clientSiteData.value.notes_admin = ''
              }
              if (typeof clientSiteData.value.notes_client === 'undefined') {
                clientSiteData.value.notes_client = ''
              }
              if (typeof clientSiteData.value.notes_public === 'undefined') {
                clientSiteData.value.notes_public = ''
              }
              if (typeof clientSiteData.value.tenant === 'undefined') {
                clientSiteData.value.tenant = { url: '', password: '', max_permits: '' }
              }
              if (typeof clientSiteData.value.geofence === 'undefined') {
                clientSiteData.value.geofence = { lat: '', lon: '', distance: '' }
              }
              if (typeof clientSiteData.value.contacts === 'undefined') {
                clientSiteData.value.contacts = []
              }
              clientSiteData.value.clientServices = clientData.services
            })
            .catch(error => {
              console.log(error)
              if (typeof error.response !== 'undefined' && error.response.status === 404) {
                clientSiteData.value = undefined
              }
            })
        })
        .catch(error => {
          console.log(error)
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            clientSiteData.value = undefined
          }
        })
    }

    return {
      clientSiteData,
    }
  },
}
</script>

<style>

</style>
